import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4b24cde"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "w-100p white-bg m-t-80",
  style: {"min-height":"calc(100vh - 413px)"}
}
const _hoisted_2 = { class: "tinymce-wrap w-1000 p-b-100" }
const _hoisted_3 = { class: "f-s-28 col-b-title wbba" }
const _hoisted_4 = { class: "f-s-14 col-b-title m-t-10 opacity-3 m-b-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tinymce = _resolveComponent("tinymce")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.content.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.content.created_at), 1),
      _createVNode(_component_tinymce, {
        content: _ctx.content.html
      }, null, 8, ["content"])
    ])
  ]))
}