
import {defineComponent, onMounted,ref} from 'vue'
import Tinymce from '@/components/Tinymce.vue'
import {useRoute, useRouter} from "vue-router";
import {getCaseDetail} from '@/api/project'

export default defineComponent({
  name: 'projectDetail',
  components: {
    Tinymce,
  },
  setup() {
    const route = useRoute();
    onMounted(()=>{
      getDetail();
    })
    let content = ref('')
    async function getDetail(){
     try{
       const { error_code, data }  =await getCaseDetail(
         route.query?.id
       );
       if(error_code === 0 ){
          content.value = data;
       }else{
         content.value = '';
       }
     }catch (e){
       content.value = '';
     }

    }

    return {
      content,
    }
  },
})
